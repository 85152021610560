import React from 'react';


const Brands = () => {
  return (
    <section className='min-h-[146px] bg-tertiary flex items-center'>
      <div
        className='container mx-auto flex md:justify-between items-center flex-wrap justify-evenly'
      >
        <h1 className='mb-4 text-accent' style={{fontSize: 25, textAlign:'center', marginBottom: 5}}>"It Always Seems Impossible, Until it is done"
        <br />
        <br/>
        - Nelson Mandela
        </h1>
      </div>
    </section>
  );
};

export default Brands;
