import React from 'react';

// import services data
import { services } from '../data';



const Services = () => {
  return (
    <section id='learning path' className='section bg-tertiary'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-services relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-28 before:hidden before:lg:block'>
            What I Plan To Learn This Year & Beyond!
          </h2>
          <p className='subtitle'>
            I believe in continuous learning, especially in the tech world. These are the Languages, Libraries,  Frameworks, and Books that I plan to learn this year!
          </p>
        </div>
        <div className='grid lg:grid-cols-4 gap-8'>
          {services.map((service, index) => {
            const { icon, name, description, learningPlan, books1, books2, books3, goal, books4 } = service;
            return (
              <div className='bg-secondary p-6 rounded-2xl' key={index}>
                <div className='text-accent rounded-sm w-12 h-12 flex justify-center items-center mb-24 text-[28px]'>
                  {icon}
                </div>
                <h4 className='text-xl font-medium mb-2'>{name}</h4>
                <p>{description}</p>
                <br />
                <br />
                {/*Goal*/}
                <p className='capitalize text-accent text-sm mb-3'>Goal: <br/>{goal}</p>
                {/*Teaching plan*/}
                {/*<a className='capitalize text-orange-800 text-sm mb-3'href={'https://learn.acloud.guru/course/6b00566d-6246-4ebe-8257-f98f989321cf/overview'}><br/>{teachingPlan}</a>*/}

                {/* Books*/}
                <a className='capitalize text-orange-800 text-sm mb-3' href="https://www.amazon.com/dp/1801816786?psc=1&ref=ppx_yo2ov_dt_b_product_details">{books1}</a>
                <br />
                <br />
                <a className='capitalize text-orange-800 text-sm mb-3' href="https://www.amazon.com/dp/1492072508?psc=1&ref=ppx_yo2ov_dt_b_product_details">{books2}</a>
                <br />
                <br />
                <a className='capitalize text-orange-800 text-sm mb-3' href="https://www.amazon.com/dp/1680507222?psc=1&ref=ppx_yo2ov_dt_b_product_details">{books3}</a>
                <br />
                <br />
                <a className='capitalize text-orange-800 text-sm mb-3' href="https://www.amazon.com/TypeScript-Design-Patterns-Best-Practices/dp/1800563426">{books4}</a>
                <br />

                {/*Learning plan*/}
                <br />
                <p className='capitalize text-accent text-sm mb-3'>Learning Plan: <br/>{learningPlan}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
