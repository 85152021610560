import React from 'react';
import Marquee from "react-fast-marquee";
import '../index.css'
// import skill data
import { skills } from '../data';

const Skills = () => {
  return (
    <section className='bg-tertiary py-12 '>
      <p className=' text-center capitalize text-accent text-sm mb-3'>Technologies I've Worked With: <span className='text-red-500'>(look for an easter egg!)</span></p>
        <Marquee
        gradient={false}
        speed={60}
        pauseOnHover={false}
        direction={'left'}
        loop={0}
        >
        <div className='grid  '>
        <div
            className='grid grid-cols-7 md:grid-flow-col '
          >
            
          {skills.map((skill, index) => {
            return (
              <div
              className='flex items-center justify-between  '
              style={{width:115, paddingLeft: 10, paddingRight: 20}}
              key={index}
              >
                
                <img className='lg:h-20 ' src={skill.image} alt='' />
              </div>
            );
          })}
        </div>
      </div>
      </Marquee>
    </section>

  );
};

export default Skills;
