//  icons
import {
  FiGithub,
  FiMail,
  FiMapPin,
  FiLinkedin
} from 'react-icons/fi';
import { SiCplusplus, SiDocker} from 'react-icons/si'
import { LiaRobotSolid } from "react-icons/lia";
import { HiChip } from "react-icons/hi";

// projects images
import Project1 from './assets/img/projects/Healthfull3.png';
import Project2 from "./assets/img/projects/PeteAlgo.png";
import Project3 from "./assets/img/projects/Bundle.png";
import Project4 from "./assets/img/projects/gamehub2.png"
import Project5 from "./assets/img/projects/CustomerData.png"
import Project6 from "./assets/img/projects/rasberryPiTrafficLight.jpeg"
import Project7 from "./assets/img/projects/C++Gui.png"

// skills images
import SkillImg1 from './assets/img/skills/big3.png';
import SkillImg2 from './assets/img/skills/reactjs.png';
import SkillImg3 from './assets/img/skills/nodejs.png';
import SkillImg4 from './assets/img/skills/Java-Emblem - Edited.png';
import SkillImg5 from './assets/img/skills/git.png';
import SkillImg6 from './assets/img/skills/pythonart.pnng.png'
import SkillImg7 from './assets/img/skills/docker.png'
import SkillImg8 from './assets/img/skills/api.png'
import SkillImg9 from './assets/img/skills/Typescript.png'
import SkillImg10 from './assets/img/skills/redux.png'
import SkillImg11 from './assets/img/skills/redis.png'
import SkillImg12 from './assets/img/skills/spring - Edited.png'
import SkillImg13 from './assets/img/skills/devops.png'
import SkillImg14 from './assets/img/skills/crying.png'



// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg9
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,

  },
  {
    image: SkillImg6,

  },
  {
    image: SkillImg7
  },
  {
    image: SkillImg8
  },
  {
    image: SkillImg11
  },
  {
    image: SkillImg10
  },
  {
    image: SkillImg12
  },
  {
    image: SkillImg13
  },
  {
    image: SkillImg14
  }
];
// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'learning path',
    href: 'learning path',
  },
  {
    name: 'Contact Me',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/felixvargasjr/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/NeverGiveUp23',
  }
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'HealthFull',
    category: 'Python',
    description: 'HealthFull is a calorie, carbs, protein and overall goal tracker using Python/Flask/Jinja/Javascript',
    github: 'https://github.com/NeverGiveUp23/HealthFull-project-'
  },
  {
    id: '2',
    image: Project2,
    name: "Pete's Algo",
    category: 'Java',
    description: 'Platform for users to post, track , and manage their algorithm solutions using Java/Spring Framework',
    github: 'https://github.com/NeverGiveUp23/petesalgos'
  },
  {
    id: '3',
    image: Project3,
    name: 'Bundle',
    category: 'Python',
    description: 'Budget Tracking application that allows users to easily track and manage their spending.'
  },
  {
    id: '4',
    image: Project5,
    name: 'CustomerData',
    category: 'Java',
    description: 'Full application built with Java, React-Typescript, Flyway, Docker, Test Containers, JUnit, AWS',
    github: 'https://github.com/NeverGiveUp23/Customer_Data'
  },
  {
    id: '5',
    image: Project4,
    name: 'GameHub',
    category: 'JavaScript',
    description: 'Full-Stack Video Game Application using React/Typescript, React Query(TanStack), ChakraUI, Custom Hooks, Rawg API for video game data.',
    github: 'https://github.com/NeverGiveUp23',
    demo: 'https://game-mceoqntxi-nevergiveup23.vercel.app/'
  },
  {
    id: '6',
    image: Project6,
    name: 'Raspberry Pi Traffic Light',
    category: 'Raspberry Pi',
    description: 'Raspberry Pi Traffic Light using Python',
    github: 'Source code coming soon'
  },
  {
    id: '7',
    image: Project7,
    name: "Interactive GUI",
    category: "C++",
    description: "This is an interactive GUI built in C++ with tools like OpenGL, and ImGui. There is a file explorer, text editor, calendar, paint, and much more.",
    github: 'https://github.com/NeverGiveUp23/guiproject',
  },
];



// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'Java',
  },
  {
    name: 'Python',
  },
  {
    name: 'C++',
  },
  {
    name: 'Raspberry Pi',
  },
  {
    name: 'coming soon',
  },
];



// services
export const services = [
  {
    icon: <SiDocker />,
    name: 'Docker',
    description:'Docker allows me to build, test, and deploy applications quickly. I use Docker to containerize my applications and deploy them to the cloud. I will be looking to receive my Docker Certified Associate(DCA) in 2023.',
    goal: 'Building Projects ',
    learningPlan: 'Throughout 2023 & 2024'
  },
  {
    icon: <SiCplusplus />,
    name: 'C/C++',
    description: 
    `Learning C/C++ to better my understanding on memory and performance programming`,
    goal: "Build some cool projects focused on low level/performance",
    learningPlan: 'Throughout 2024/2025',
    books1: 'A Tour of C++',
    books2: 'C++ Development',
    books3: 'Expert C++',
  },
  {
    icon: <LiaRobotSolid />, 
    name: 'Machine Learning & Ai',
    description: "In my current studies I will be taking intermediate and advanced Machine Learning/Ai classes, this will further my understanding while also better prepare me to program Machine Learning and Ai models",
    learningPlan: 'University & Throughout my career',
    goal:'Build & train AI models and make a cohesive project surrounding the two topics'
  },
  {
    icon: <HiChip />,
    name: 'Quantum Computing',
    description:
      `This is a long-term journey of mines, I would love to learn about Quantum Computing and hope to do deep research and understanding. I will grow my high level math skills as well as learning on Qubits and Quantum Algorithms`,
    goal: 'Learn more on Qubits',
    learningPlan: 'LIfe-Long'
  },
];


// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Want to reach me?',
    subtitle: 'Click the link below 👇 ',
    description: 'Email me at felixvjr0@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'New Jersey, USA',
  },
];
