import React, { useState, useEffect } from 'react';
const Project = ({ item}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Check if the project was previously active from local storage
    const activeProjectId = localStorage.getItem('activeProjectId');
    if (activeProjectId === item.id) {
      setIsActive(true);
    }
  }, [item.id]);

  const handleDemoClick = () => {
    setIsActive(true);
    // Store the active project ID in local storage
    localStorage.setItem('activeProjectId', item.id);
    // redirecting to the demo page
    window.location.href = item.demo;
  };

  return (
    <div key={item.id} className='flex flex-col items-center text-center'>
      <div className='mb-8'>
        <img className='rounded-2xl ' src={item.image} alt='' />
      </div>
      <p className='capitalize text-accent text-sm mb-3'>{item.category}</p>
      <h3 className='text-2xl font-semibold capitalize mb-3'>{item.name}</h3>
      <p className='text-base max-w-md'>
        {item.description}
      </p>
      <a className='capitalize text-accent text-sm mb-3' href={item.github}>GitHub </a>
      {item.demo && (
        <a
          className={`capitalize text-red-700 text-sm mb-3 ${isActive ? 'text-blue-500' : ''}`}
          href={item.demo}
          onClick={handleDemoClick}
        >
          Live Demo
        </a>
      )}
      
    </div>
  );
};

export default Project;
