import React from 'react';
// import navigation data
import { navigation } from '../data';

// import Link
import { Link } from 'react-scroll';
// import img
import Image from '../assets/img/ReadyPlayerMe-Avatar - Edited.png';


const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
          style={{height:650}}
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Felix Vargas Jr
              </h2>
              <p className='mb-4 text-accent'>
                Software Engineer
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
                As someone with a diverse background in retail management and the food industry, I bring a unique perspective to the world of tech. I am grateful for the opportunity to learn and grow alongside others in the field, and I am committed to improving every day. I made the decision to obtain my Bachelor's Degree in Computer Science from Western Governor's University.
                <br />
                <br />
                My goal is to continuously receive feedback on my skills and use that feedback to learn continuously. My communication within the industry, allows me to reach new levels of proficiency and success.

              </p>
            </div>
            {navigation
            // Just get  the contact me from the navigation object
              .filter((item) => item.name === 'Contact Me')
            .map((item, idx) => {
              return (
                <button
                  className='text-white hover:text-accent cursor-pointer btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'
                  key={idx}
                >
                  <Link
                    to={item.href}
                    activeClass='active'
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className='transition-all duration-300 '
                  >
                    {item.name }
                  </Link>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
